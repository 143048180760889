import { useAuthenticator } from '@aws-amplify/ui-react';
import { ETFMenu, ETFNavMenuIconButton, Layout } from '@cfra-nextgen-frontend/shared';
import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { HorizontalPagePaddingString, MaxPageWidthString } from '@cfra-nextgen-frontend/shared/src/utils';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Breakpoint, ThemeOptions, ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextLogo from 'components/TextLogo/TextLogo';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageNames } from 'utils/enums';
import { topNavItems } from 'features/topNavigation/topNavItems';
import { UsageSummary } from 'features/usageSummary';
import MenuIcon from '@mui/icons-material/Menu';

const DefaultLandingPage: { name: string; href: string } = {
    name: PageNames.Home,
    href: '/',
};

export function TopNavigation({
    topNavigationContentHeight,
    toggleDrawer,
}: {
    topNavigationContentHeight: number;
    toggleDrawer: () => void;
}) {
    const [currentActiveTab, setCurrentActiveTab] = useState<number | boolean>(false);
    const { user, signOut } = useAuthenticator((context) => [context.user, context.signOut]);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const isScreenDisplay = useMediaQuery('screen');

    useEffect(() => {
        const activeTabIndex = Object.values(topNavItems)
            .map((value) => value.href)
            .indexOf(`/${pathname.slice(1).split('/')[0]}`);

        setCurrentActiveTab(activeTabIndex > -1 ? activeTabIndex : false);
    }, [pathname, setCurrentActiveTab]);

    const theme = createTheme(breakpointsTheme as ThemeOptions, {
        components: {
            MuiAppBar: {
                defaultProps: {
                    position: isScreenDisplay ? 'sticky' : 'static',
                },
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: '#FFF',
                    },
                    root: {
                        borderBottom: 'solid #E4E5E9 1px',
                        borderTop: 'solid #3078B5 4px',
                        boxShadow: 'none',
                        zIndex: 1500,
                    },
                },
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#3078B5',
                        fontSize: 18,
                        fontFamily: fontFamilies.GraphikLight,
                        height: 48,
                        textTransform: 'uppercase',
                        width: 48,
                    },
                },
            },
            MuiContainer: {
                defaultProps: {
                    disableGutters: true,
                    maxWidth: MaxPageWidthString as Breakpoint,
                },
                styleOverrides: {
                    root: {
                        maxWidth: MaxPageWidthString,
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        padding: 0,
                        marginLeft: '18px',
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            color: '#3078B5',
                        },
                        color: '#0B2958',
                        fontFamily: fontFamilies.GraphikMedium,
                        fontSize: '15px',
                        lineHeight: 1,
                        marginRight: '28px',
                        minWidth: '0px',
                        padding: 0,
                        paddingBottom: '25px',
                        paddingTop: '25px',
                        textTransform: 'none',
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        background: '#4375BB',
                        borderRadius: '5px',
                        height: '4px',
                    },
                    root: {
                        [breakpointsTheme.breakpoints.down('lg')]: {
                            visibility: 'collapse',
                            width: 0,
                        },
                        paddingTop: '3px',
                    },
                },
            },
            MuiToolbar: {
                defaultProps: {
                    disableGutters: true,
                },
                styleOverrides: {
                    root: {
                        paddingLeft: HorizontalPagePaddingString,
                        paddingRight: HorizontalPagePaddingString,
                        display: 'flex',
                        height: topNavigationContentHeight,
                        justifyContent: 'space-between',
                    },
                },
            },
            MuiTypography: {
                defaultProps: {
                    mr: 1,
                    noWrap: true,
                    variant: 'h6',
                },
                styleOverrides: {
                    root: {
                        color: '#3078B5',
                        fontSize: '28px',
                        fontFamily: fontFamilies.GraphikLight,
                        lineHeight: 1.2,
                        textDecoration: 'none',
                    },
                },
            },
        },
    });
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentActiveTab(newValue);
    };

    const cfraLogoStyles = {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    };

    return (
        <ThemeProvider theme={theme}>
            <AppBar>
                <Container>
                    <Toolbar>
                        <Button
                            onClick={toggleDrawer}
                            sx={{
                                marginLeft: 0,
                                paddingRight: '18px',
                                [theme.breakpoints.up('md')]: {
                                    display: 'none',
                                },
                            }}>
                            <MenuIcon />
                        </Button>
                        {/* https://stackoverflow.com/questions/69455056/override-box-component-in-createtheme/69455458#69455458 */}
                        {Object.keys(topNavItems).length > 0 ? (
                            <ETFMenu.ETFMenu
                                menuItems={Object.keys(topNavItems).map((key) => {
                                    return {
                                        itemName: topNavItems[key].name,
                                        callback: () => {
                                            globalThis.analytics?.registerAction?.({
                                                action: `top navigation button : ${topNavItems[
                                                    key
                                                ].name.toLocaleLowerCase()}`,
                                            });
                                            navigate(topNavItems[key].href);
                                        },
                                    };
                                })}>
                                <ETFNavMenuIconButton
                                    sx={{
                                        marginLeft: 0,
                                        paddingRight: '18px',
                                        [theme.breakpoints.up('lg')]: {
                                            display: 'none',
                                        },
                                    }}
                                />
                            </ETFMenu.ETFMenu>
                        ) : null}
                        <Box
                            data-testid='cfra-logo'
                            sx={cfraLogoStyles}
                            onClick={() => {
                                globalThis.analytics?.registerAction?.({
                                    action: 'logo',
                                });
                                navigate(DefaultLandingPage.href);
                            }}>
                            <TextLogo fontSize={isMediumScreen ? 40 : 28} />
                        </Box>
                        <div style={{ flexGrow: 1 }} />
                        {Object.keys(topNavItems).length > 0 ? (
                            <Tabs value={currentActiveTab} onChange={handleChange} aria-label='top navigation tabs'>
                                {Object.keys(topNavItems).map((key, index) => {
                                    const topNavItem = topNavItems[key];
                                    return (
                                        <Tab
                                            label={topNavItem.name}
                                            key={index}
                                            {...Layout.a11yProps(index)}
                                            onClick={() => {
                                                globalThis.analytics?.registerAction?.({
                                                    action: `top navigation tabs : ${topNavItem.name.toLocaleLowerCase()}`,
                                                });
                                                navigate(topNavItem.href);
                                            }}
                                        />
                                    );
                                })}
                            </Tabs>
                        ) : null}
                        <div style={{ flexGrow: 1 }} />
                        <UsageSummary labelText={isMediumScreen ? 'Monthly Usage: ' : ''} />
                        <ETFMenu.ETFMenu
                            menuItems={[
                                {
                                    itemName: 'Logout',
                                    callback: () => {
                                        globalThis.analytics?.registerAction?.({
                                            action: 'avatar : logout',
                                        });
                                        signOut();
                                    },
                                },
                            ]}>
                            <Tooltip title={user?.username || ''}>
                                <IconButton>
                                    <Avatar>{user?.username?.[0]}</Avatar>
                                </IconButton>
                            </Tooltip>
                        </ETFMenu.ETFMenu>
                    </Toolbar>
                </Container>
            </AppBar>
        </ThemeProvider>
    );
}

export default TopNavigation;
