import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { useState } from 'react';
import { BASE_API_URL } from '@cfra-nextgen-frontend/shared/src/config';
import { useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchWithAuth } from '../../../utils/api';
import { SnackMessageForm } from '@cfra-nextgen-frontend/shared/src/components/Snack/SnackMessageForm';
import { useSnackbar } from 'notistack';
import { DefaultCFRASnack } from '@cfra-nextgen-frontend/shared';

type Model = {
    id: string;
    name: string;
};

type Tool = {
    id: string;
    name: string;
};

export function NewChat() {
    const [newMessage, setNewMessage] = useState('');
    const [selectedModel, setSelectedModel] = useState('3');
    const [selectedTool, setSelectedTool] = useState('1');
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const ShowSnack = DefaultCFRASnack(enqueueSnackbar);

    const { data: models = [], isLoading: isLoadingModels } = useQuery<Model[]>('models', async () => {
        const api = `${BASE_API_URL}/models`;
        const response = await fetchWithAuth(api);
        if (!response.ok) throw new Error('Failed to fetch models');
        return response.json();
    });

    const { data: tools = [], isLoading: isLoadingTools } = useQuery<Tool[]>('tools', async () => {
        const api = `${BASE_API_URL}/tools`;
        const response = await fetchWithAuth(api);
        if (!response.ok) throw new Error('Failed to fetch tools');
        return response.json();
    });

    const { mutate: sendMessage, isLoading: isSendingMessage } = useMutation(
        async () => {
            const api = `${BASE_API_URL}/chat`;
            const newMessageObject = {
                role: 'user' as const,
                text: newMessage,
            };

            const response = await fetchWithAuth(api, {
                method: 'POST',
                body: JSON.stringify({
                    messages: [newMessageObject],
                    thread_id: null,
                    model_id: selectedModel,
                    tool_id: selectedTool,
                }),
            });

            if (!response.ok) throw new Error('Failed to send message');
            return response.json();
        },
        {
            onSuccess: (result) => {
                const responseThreadId = result.thread_id;
                queryClient.invalidateQueries('threads');
                navigate(`/${responseThreadId}`);

                // Update the usage
                const currentUsage = queryClient.getQueryData<number>(['usage']) ?? 0;
                queryClient.setQueryData(['usage'], +currentUsage + (result.cost ?? 0));
            },
            onError: () => {
                ShowSnack(
                    SnackMessageForm({
                        message: 'Failed to send message',
                    }),
                );
            },
        },
    );

    return (
        <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            height='100%'
            sx={{ minHeight: 'calc(100vh - 90px)', backgroundColor: '#f8f8f8', margin: '0 10px' }}>
            <Box sx={{ width: '90%', minHeight: '200px' }}>
                <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='stretch'
                    flexGrow={0}
                    justifyContent='space-between'
                    component='form'
                    onSubmit={() => sendMessage()}
                    gap={1}>
                    <TextField
                        label='Enter your message here (Ctrl+Enter to send)'
                        multiline
                        maxRows={10}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        disabled={isSendingMessage}
                        onKeyDown={(e) => {
                            if (e.ctrlKey && e.key === 'Enter') {
                                sendMessage();
                            }
                        }}
                        sx={{
                            margin: 0,
                            height: '100%',
                            '& .MuiInputBase-root': {
                                height: '100%',
                            },
                        }}
                    />
                </Box>
                <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='right'
                    justifyContent='end'
                    gap={1}
                    sx={{ marginTop: '10px' }}>
                    {isLoadingModels ? (
                        <CircularProgress />
                    ) : (
                        <FormControl>
                            <InputLabel id='model-select-label'>Model</InputLabel>
                            <Select
                                labelId='model-select-label'
                                id='model-select'
                                value={selectedModel}
                                label='Model'
                                onChange={(e) => setSelectedModel(e.target.value)}>
                                {models.map((m) => (
                                    <MenuItem key={m.id} value={m.id}>
                                        {m.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    {isLoadingTools ? (
                        <CircularProgress />
                    ) : (
                        <FormControl>
                            <InputLabel id='tool-select-label'>Tool</InputLabel>
                            <Select
                                labelId='tool-select-label'
                                id='tool-select'
                                value={selectedTool}
                                label='Tool'
                                onChange={(e) => setSelectedTool(e.target.value)}>
                                {tools.map((t) => (
                                    <MenuItem key={t.id} value={t.id}>
                                        {t.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    <Button
                        variant='contained'
                        endIcon={isSendingMessage ? <CircularProgress size={20} color='inherit' /> : <SendIcon />}
                        onClick={() => sendMessage()}
                        disabled={newMessage.length === 0 || isSendingMessage}>
                        {isSendingMessage ? 'Sending...' : 'Send'}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
