import {
    determineGetData,
    determinePrefetchQuery,
    determineUseData,
    determineUseMultipleData,
} from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ApiInstanceTypes, ApiNames } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { Auth, API } from 'aws-amplify';

export async function fetchWithAuth(url: string, options: RequestInit = {}) {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    const defaultHeaders = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    };

    return fetch(url, {
        ...options,
        headers: {
            ...defaultHeaders,
            ...options.headers,
        },
    });
}

export const getData = determineGetData({
    apiNameToApiDetails: {
        [ApiNames.AI]: {
            instance: API,
            instanceType: ApiInstanceTypes.AwsAmplify,
        },
    },
});

export const UseData = determineUseData(getData);
export const UseMultipleData = determineUseMultipleData(getData);
export const prefetchQuery = determinePrefetchQuery(getData);
